<template>
  <client-only>
    <el-backtop :right="10">
      <template v-if="backTopIcon">
        <div
          class="icon"
          :style="{ 'background-color': iconFrom.ctrl.isHover ? backTopIcon.selectBackgroundColor : '#fff' }"
          @mouseover="iconFrom.ctrl.isHover = true"
          @mouseout="iconFrom.ctrl.isHover = false"
        >
          <img
            v-if="backTopIcon.defaultIcon && !iconFrom.ctrl.isHover"
            :src="backTopIcon.defaultIcon"
            alt=""
          >
          <img
            v-if="backTopIcon.selectIcon && iconFrom.ctrl.isHover"
            :src="backTopIcon.selectIcon"
            alt=""
          >
          <div
            v-if="backTopIcon.showIconName"
            class="icon_name"
            :style="{ color: iconFrom.ctrl.isHover ? backTopIcon.selectTextColor : 'inherit' }"
          >
            {{ backTopIcon.iconName }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="wh100pc default_backtop">
          <i class="iconfont icon-backtop font12 c_333" />
        </div>
      </template>
    </el-backtop>
  </client-only>
</template>

<script setup>
import { computed, ref, onMounted, reactive } from 'vue'
import { useLayoutStore } from '@/stores/layout'
const layoutStore = useLayoutStore()
const suspense = computed(() => layoutStore.suspense)
onMounted(() => {
  layoutStore.setSuspense()
})
const backTopIcon = computed(() => {
  if (!suspense.value.suspendShow) return null
  if (suspense.value && suspense.value.iconList.length) {
    const icon = suspense.value.iconList.find(item => {
      return item.suspendIconType === 2
    })
    if (!icon) return null
    const { selectBackgroundColor, selectTextColor } = suspense.value
    return { ...icon, selectBackgroundColor, selectTextColor }
  }
})
const iconFrom = reactive({
  ctrl: {
    isHover: false
  }
})

</script>

<style lang="scss" scoped>
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  border-radius: 5px;
  transition: all 0.3s;
  img {
    width: 24px;
    height: 24px;
    transition: all 0.3s;
  }
  .icon_name {
    margin-top: 5px;
    font-size: 12px;
    line-height: 18px;
    transition: all 0.3s;
  }
}
.default_backtop{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
